import styled from "styled-components";
import Header from "./components/Banner/Header";
import ProfComponent from "./components/Banner/ProfComponent";
// import Clients from "./components/Clients/Clients";
import Footer from "./components/Footer/Footer";
import Projects from "./components/Projects/Projects";
import Services from "./components/Service/Services";
function App() {
  return (
    <Container>
      <Banner>
        <Header />
        <ProfComponent />
      </Banner>
      <Services />
      <LightColor>
        <Projects />
      </LightColor>
      {/* <Clients /> */}
      <LightColor>
        <Footer />
      </LightColor>
    </Container>
  );
}

export default App;

const Container = styled.div``;
const Banner = styled.div`
  background: linear-gradient(159deg, rgb(45, 45, 58, .5) 0%, rgb(43, 43, 53, .5) 100%);
  height: 100vh;
  padding-bottom:55rem;
  @media (max-width: 720px) {
    height: 100%;
    padding-bottom: 2rem;

  }
`;

const LightColor = styled.div`
  background: linear-gradient(159deg, rgb(45, 45, 58, .4) 0%, rgb(43, 43, 53, .4  ) 100%);
`;
